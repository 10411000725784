import React from "react"

import Layout from "../components/layout"
// import Image from "../components/image"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO
      title="Sucursales y horarios"
      description="Nos encontramos en Av. Zaragoza #2094 Col. Nueva C.P. 21100. Ademas contamos con diversas sucursales a los largo de la ciudad."
    />
    <div className="mc-wrapper-2 extra-padding">
      <div className="mc-wrapper-3">
        <h1 className="title">Sucursales</h1>
        <div className="map-cols clearfix">
          <div className="column">
            <div className="map zaragoza">
              <div className="image" />
              <div className="data clearfix">
                <div className="map-title">Matriz Zaragoza</div>
                <div className="map-address">
                  Av. Zaragoza #2094 Col. Nueva C.P. 21100
                </div>
                <div className="map-hours">Lun a Sab, 7:30 AM a 8:00 PM</div>
                <div className="map-phone">
                  <i className="fa fa-phone" /> (686) 552 80 25
                </div>
              </div>
            </div>
          </div>
          <div className="column col-50">
            <div className="map duo infonavit-cucapah">
              <div className="image" />
              <div className="data clearfix">
                <div className="map-title">Infonavit-Cucapah</div>
                <div className="map-address">
                  Blvd. Vicente Lombardo Toledano #1828. Fracc. Infonavit
                  Cucapah C.P. 21340
                </div>
                <div className="map-hours">Lun a Sab, 8:00 AM a 7:00 PM</div>
                <div className="map-phone">
                  <i className="fa fa-phone" /> (686) 557 84 38
                </div>
              </div>
            </div>
          </div>
          <div className="column col-50">
            <div className="map duo alamitos">
              <div className="image" />
              <div className="data clearfix">
                <div className="map-title">Alamitos</div>
                <div className="map-address">
                  Manuel Acuña #279 Col. Alamitos C.P. 21210
                  <br />
                  &nbsp;
                </div>
                <div className="map-hours">Lun a Sab, 8:00 AM a 7:00 PM</div>
                <div className="map-phone">
                  <i className="fa fa-phone" /> (686) 567 34 38
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default IndexPage
